import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const Nav = styled.div`
  font-size: 24px;
  letter-spacing: 1.1px;
  margin: 14px 40px 0px 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform: scale(1);

  a {
    margin-right: 20px;
  }

  a:not(.active) {
    text-decoration: none;
    font-weight: 200;
  }

  a:hover {
    transform: scale(1.02);
  }
`

const Navigation = ({ location }) => {
  const active = "projects"
  return (
    <Nav>
      <Link to="/" activeClassName="active">
        Blog
      </Link>
      <Link to="/projects" activeClassName="active">
        Projects
      </Link>
      <Link to="/photography" activeClassName="active">
        Photography
      </Link>
      <Link to="/about" activeClassName="active">
        About
      </Link>
    </Nav>
  )
}

export default Navigation
