import * as React from "react"
import { Link } from "gatsby"
import Navigation from "./navigation"
import styled from "@emotion/styled"
import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram"
import { AiOutlineLinkedin } from "@react-icons/all-files/ai/AiOutlineLinkedin"
import { AiOutlineGithub } from "@react-icons/all-files/ai/AiOutlineGithub"
import { StaticImage } from "gatsby-plugin-image"

const Title = styled.div`
  display: flex;
  justify-content: center;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 10px;
`

const Footer = styled.div`
  text-align: center;
`

const Social = styled.div`
  display: flex;
  justify-content: center;

  a {
    margin: 10px;
    font-size: 24px;
  }
`

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  header = (
    <Header>
      <Title>
        <Link to="/">
          <StaticImage
            layout="fixed"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../images/p_m_logo.png"
            width={100}
            height={45}
            quality={100}
            alt="Profile picture"
          />
        </Link>
      </Title>
      <Navigation location={location} />
    </Header>
  )

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        <Footer>
          @ Phillip Michalowski
          <Social>
            <a href="https://www.instagram.com/onocy/" target="_blank">
              <AiOutlineInstagram />
            </a>
            <a
              href="https://www.linkedin.com/in/phillip-michalowski/"
              target="_blank"
            >
              <AiOutlineLinkedin />
            </a>
            <a href="https://github.com/onocy" target="_blank">
              <AiOutlineGithub />
            </a>
          </Social>
        </Footer>
      </footer>
    </div>
  )
}

export default Layout
